<template>
	<!-- NOTE: Don't remove div, it's important! -->
	<div :class="contentType.container ?? ''">
		<img
			:src="src"
			:alt="alt"
			:class="contentType" />
	</div>
</template>

<script setup>
	const props = defineProps({
		src: {
			type: String,
			default: "/icons/basic-fill.svg",
		},
		alt: {
			type: String,
			default: "Image",
		},
		type: {
			type: String,
			default: "w-full",
		},
	});

	const contentType = computed(() => {
		switch (props.type) {
			case "cover":
				return {
					container: "w-full overflow-hidden",
					image: "object-cover w-full h-full",
				};
			case "contain":
				return "object-contain";
			case "fill":
				return "object-fill";
			case "none":
				return "object-none";
			case "scale-down":
				return "object-scale-down";
			default:
				return "w-full";
		}
	});
</script>
